.fa-pointer {
    cursor: pointer;
}

.fa-white-icon {
    color: white;
}

.mat-icon-button {
    background-color: unset;
    color: white;
}

.btn-space {
    padding-right: 10px;
}

// https://copy-paste-css.com/

.fa-btn-primary {
    display: inline-block;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    padding: 2px 16px;
    height: 38px;
    min-width: 96px;
    min-height: 38px;
    border: none;
    color: #fff;
    background-color: rgb(88, 101, 242);
    transition: background-color 0.17s ease, color 0.17s ease;
    :hover {
        background-color: rgb(71, 82, 196);
    }
}

.fa-btn-secondary {
    display: inline-block;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    padding: 2px 16px;
    height: 38px;
    min-width: 96px;
    min-height: 38px;
    border: none;
    color: #fff;
    background-color: #4f545c;
    transition: background-color 0.17s ease, color 0.17s ease;
    :hover {
        background-color: #77797d;
    }
}

.fa-btn-without-background {
  background-color: unset;
  border: unset;
}

.fa-input-box {
    border: 3px solid rgb(134, 134, 134);
    border-radius: 5px;
    min-height: 50px;
    line-height: normal;
    color: #282828;
    display: block;
    width: 100%;
    box-sizing: border-box;
    user-select: auto;
    font-size: 16px;
    padding: 0 6px;
    padding-left: 12px;
    :focus {
        border: 3px solid #5551ff;
    }
}


.fa-text-area-not-resize {
        resize: none;
}